<template>
  <v-card>
    <v-container fill-height>
      <v-form
        ref="formCpf"
        @submit.prevent="getUserEmailByCpf"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="cpf"
              outlined
              dense
              label="CPF"
              :rules="$rules.cpf"
              required
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              type="submit"
            >
              Avançar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import ChangePasswordService from '../../../services/change-password.service';

export default {
  data() {
    return {
      cpf: '',
    };
  },
  methods: {
    resetForm() {
      this.$refs.formCpf.reset();
      this.$refs.formCpf.resetValidation();
    },

    async getUserEmailByCpf() {
      if (!this.$refs.formCpf.validate()) {
        this.$toast.error('Verifique os campos, por favor!');
        return;
      }

      try {
        const { email, isAuthUser } = await ChangePasswordService.getUserEmailByCpf(this.cpf);
        this.$emit('cpf-returned', { email, isAuthUser, cpf: this.cpf });
        this.$toast.success('Ação bem sucedida!');
      } catch (e) {
        this.$handleHttpError(e);
      } finally {
        this.resetForm();
      }
    },
  },
};
</script>
